<template>
  <div>
    <div class="centered">
      <lottie-player
        src="https://assets4.lottiefiles.com/packages/lf20_ebpy0jqw.json"
        background="transparent"
        speed="1"
        style="width: 300px; height: 300px;"
        autoplay></lottie-player>
      <div class="text-center">
        <h3>
          {{ $t('global.changeLanguangeSuccess') }}
        </h3>
        <v-btn
          color="primary"
          large
          dark
          class="mt-6 font-weight-bold"
          @click="$router.push('/liff/booking')"
        >
          {{ $t('global.goToBooking') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import liff from '@line/liff'

export default {
  async mounted () {
    await this.liffInit()
  },
  methods: {
    async liffInit () {
      try {
        this.$i18n.locale = this.$route.query.language
        const member = JSON.parse(localStorage.getItem('member'))

        if (member && member.member.lineUid) {
          let lineUid
          if (process.env.VUE_APP_BRANCH === 'trang') lineUid = member.member.lineUidTrang
          if (process.env.VUE_APP_BRANCH === 'sathorn') lineUid = member.member.lineUidSathorn
          const payload = {
            lineUid,
            language: this.$route.query.language,
            type: this.$route.query.type
          }
          await axios.post(`${process.env.VUE_APP_API_URL}/line/richmenu/changeLanguage?branch=${process.env.VUE_APP_BRANCH}`, payload)
          localStorage.setItem('language', this.$route.query.language)
          this.$i18n.locale = this.$route.query.language
        } else {
            await liff.init({ liffId: process.env.VUE_APP_LIFF_ID })
            if (liff.isLoggedIn()) {
              const profile = await liff.getProfile()
              const { userId } = profile
              const payload = {
                lineUid: userId,
                language: this.$route.query.language,
                type: this.$route.query.type
              }
              await axios.post(`${process.env.VUE_APP_API_URL}/line/richmenu/changeLanguage?branch=${process.env.VUE_APP_BRANCH}`, payload)
              localStorage.setItem('language', this.$route.query.language)
              this.$i18n.locale = this.$route.query.language
            } else {
              liff.login({ redirectUri: `${process.env.VUE_APP_FRONTEND_URL}/api/change/liff` })
              const profile = await liff.getProfile()
              const { userId } = profile
              const payload = {
                lineUid: userId,
                language: this.$route.query.language,
                type: this.$route.query.type
              }
              await axios.post(`${process.env.VUE_APP_API_URL}/line/richmenu/changeLanguage?branch=${process.env.VUE_APP_BRANCH}`, payload)
              localStorage.setItem('language', this.$route.query.language)
              this.$i18n.locale = this.$route.query.language
            }
          }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
<style scoped>
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
</style>
